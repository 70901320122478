import request from '@/common/utils/request'
import Vue from "vue";

export function getDeviceList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device/get-device-list',
    method: 'post',
    data
  })
}

export function editDevice(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device/edit-device',
    method: 'post',
    data
  })
}
