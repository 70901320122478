<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <!--          <el-form :inline="true" size="medium" :model="searchForm">-->
          <!--            <el-form-item label="">-->
          <!--              <el-input v-model="searchForm.username" size="mini" placeholder="请输入姓名"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-form>-->
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      ref="elTable"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <!--      <el-table-column-->
      <!--        type="selection"-->
      <!--        align="center"-->
      <!--        width="55">-->
      <!--      </el-table-column>-->
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="name"
        label="设备名称"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="sn"
        label="设备sn"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="plateTitle"
        label="会议名称"
        min-width="300"
      >
      </el-table-column>

      <el-table-column
        fixed
        align="center"
        prop="updateTime"
        label="更新时间"
        min-width="100"
      >
        <template scope="scope">
          {{ fmtDate2(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="updateStatus"
        label="更新状态"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="updateFailReason"
        label="失败原因"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="note"
        label="备注"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        prop="address"
        label="操作"
        min-width="150"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="openDeviceUserManage(scope.$index, scope.row)"
            >人员管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      title="修改"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="图片" label-width="100px" prop="img">
          <el-input
            v-model="editForm.img"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="100px" prop="note">
          <el-input
            v-model="editForm.note"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="会议" label-width="100px" prop="role">
          <el-select
            v-model="editForm.plateId"
            size="small"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in plateList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getDeviceList, editDevice } from "../../../api/device";
import { getPlateList, getPlateListWithGroup } from "../../../api/plate";

export default {
  name: "deviceManage",
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      loadingDiv: null,
      editForm: {
        id: 0,
        plateId: "",
        img: "",
        note: "",
      },
      editRules: {},
      plateList: [],

      searchForm: {},
    };
  },
  watch: {},
  mounted() {
    this.init();

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };
  },
  methods: {
    openDeviceUserManage(index, row) {
      // this.$store.commit('addTab',{
      //   name: "/device/device_user_manage/"+row.id,
      //   title: row.name+" - 人员管理",
      //   icon: "",
      //   closable: true,
      // })
      let item = {
        componentName: "deviceUserManage",
        icon: "",
        path: "/device/device_user_manage/" + row.id,
        title: row.name + " - 人员管理",
      };
      this.$store.commit("openTab", {
        item: item,
        router: (path) => {
          this.$router.replace(path);
        },
      });
    },
    init() {
      getPlateList().then((res) => {
        if (res.code) {
          this.plateList = res.data;
          // this.plateList.unshift({title: "请选择会议", id:''})
        }
      });
    },
    handleEdit(index, row) {
      this.editForm.id = row.id;
      this.editForm.plateId = row.plateId;
      this.editForm.img = row.img;
      this.editForm.note = row.note;

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          const loadingDiv = util.showLoading("添加中...");

          editDevice(this.editForm)
            .then((res) => {
              let title = "修改";
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.getDataList(1);
    },
    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2)) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };

      getDeviceList(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tabs--border-card {
  box-shadow: none;
}
</style>
